import React from 'react'
import {
  Paper,
  Avatar,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core'
import Style from './index.module.scss'
import PubSub from 'pubsub-js'
import { useMount } from 'ahooks'

function VcHeader() {
  const [count, setCount] = React.useState(5)
  const [loadWeather, setLoad] = React.useState(false)
  const [topBar, setTopBar] = React.useState(false)
  const getPositionPower = () => {
    navigator.geolocation &&
      navigator.geolocation.getCurrentPosition(
        (e) => {
          setLoad(true)
          setTimeout(() => {
            setLoad(false)
          }, 3500)
        },
        () => {
          setTopBar(false)
          PubSub.publish('error', '无法使用定位')
        }
      )
  }
  const unUse = () => {
    setTopBar(false)
  }
  const countDown = (c) => {
    if (c > 0) {
      setTimeout(() => {
        setCount((c -= 1))
        countDown(c)
      }, 1000)
    } else {
      setTopBar(false)
    }
  }

  const showOnce = () => {
    return window.localStorage.getItem('geo')
  }
  const setDone = () => {
    return window.localStorage.setItem('geo', 'done')
  }
  useMount(() => {
    if (!showOnce()) {
      countDown(count)
      setTopBar(true)
      setDone()
    }
  })
  const handleClick = () => {
    PubSub.publish('handleMenu', true)
  }
  React.useEffect(() => {
    if (loadWeather) {
      const myScript = document.createElement('script')
      myScript.src =
        'https://widget.qweather.net/standard/static/js/he-standard-common.js?v=2.0'
      document.body.appendChild(myScript)
    }
  }, [loadWeather])
  return (
    <div className={Style.wrapper}>
      <Paper
        className={Style.power}
        square
        style={
          topBar
            ? { transform: 'translateY(0)' }
            : { transform: 'translateY(-20vw)' }
        }
      >
        <Typography className={Style.self}>
          授权一些基础权限可以得到更个性化的内容
        </Typography>
        <ButtonGroup className={Style.btnGroup} size="small">
          <Button color="primary" onClick={getPositionPower}>
            确定
          </Button>
          <Button onClick={unUse}>不使用({count}s)</Button>
        </ButtonGroup>
        {/* {loadWeather ? (
          <script src="https://widget.qweather.net/standard/static/js/he-standard-common.js?v=2.0"></script>
        ) : null} */}
      </Paper>

      <Paper
        className={Style.container}
        square
        style={
          topBar
            ? { transform: 'translateY(0)' }
            : { transform: 'translateY(-20vw)' }
        }
      >
        <Avatar
          src="https://img0.baidu.com/it/u=3069770223,3386636925&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
          onClick={handleClick}
        />
        <div className={Style.textWrapper} onClick={handleClick}>
          <Typography variant="h6" gutterBottom className={Style.name}>
            Vencent Lum
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            className={Style.subTitle}
          >
            未来是你的！
          </Typography>
        </div>
      </Paper>
    </div>
  )
}

export default VcHeader
