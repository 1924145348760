import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AboutMe from '@/pages/AboutMe/index'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Style from './index.module.scss'
import ReactSeamlessScroll from 'react-seamless-scroll'

const drawerWidth = '60vw'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: 'linear-gradient(90deg, #eae5c9, #6cc6cb);',
  },
  listItem: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 5,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}))
function VcDrawer(props) {
  const classes = useStyles()
  const theme = useTheme()
  const { window } = props
  const container =
    window !== undefined ? () => window().document.body : undefined

  const handleDrawerClose = () => {
    props.menuClose()
  }

  return (
    <Drawer
      container={container}
      variant="temporary"
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={props.show}
      onClose={handleDrawerClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <div className={Style.wrapper}>
        <img
          className={Style.yq}
          src="https://img0.baidu.com/it/u=3069770223,3386636925&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
          alt="雅琴"
        />
        <div className={Style.description}>
          <ReactSeamlessScroll mode="horizontal" style={{ height: 20 }}>
            <div className={Style.descText}>
              圣经里面说：世界上追不来的三件事是说出的话、射出的箭和失去的机会
            </div>
          </ReactSeamlessScroll>
        </div>
        <List className={Style.list}>
          <ListItem className={classes.listItem}>
            <AboutMe />
          </ListItem>
        </List>

      </div>
    </Drawer>
  )
}

export default VcDrawer
