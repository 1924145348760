import React from 'react'
import VcListItem from '@/pages/VcListItem'
import IscrollLuo from 'iscroll-luo'
import Style from './index.module.scss'
import { getMessage } from '@/utils/fetch'
import { useRequest } from 'ahooks'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import { Avatar, Typography } from '@material-ui/core'
import { fetchData } from '@/utils/index'

function VcList ({ source, pageMax }) {
  const [dataSource, setDataSource] = React.useState([])
  const [page_max, setPageMax] = React.useState(1)
  const [page, setPage] = React.useState(1)
  React.useEffect(() => {
    if (source) {
      setDataSource(source)
    }
  }, [source])
  React.useEffect(() => {
    if (pageMax) {
      setPageMax(pageMax)
    }
  }, [pageMax])
  const { run } = useRequest(fetchData, {
    manual: true,
    onSuccess: (result) => {
      if (result) {
        if (result.data.list instanceof Array) {
          setDataSource([...dataSource, ...result.data.list])
        } else {
          setDataSource([...dataSource])
          setPage(page - 1)
        }
      }
    },
    // onError: (err) => {
    //   setErrMessage(`${err.data}->${err.status}->${err.statusText}`)
    //   setMessageOpen(true)
    // },
  })
  let limit = 5

  const loadMore = (load) => {
    run({
      url: getMessage,
      options: {
        method: 'GET',
        params: {
          page: load,
          limit,
        },
      },
    })
  }

  const height = window.innerHeight + 'px'

  const handLoadMore = async () => {
    if (page < page_max) {
      setPage(page + 1)
      loadMore(page + 1)
    } else {
      setDataSource([...dataSource])
    }
  }

  const handleChangeItem = (i, items) => {
    let arr =
      dataSource.map((it) => {
        return it
      }) || []
    //fixed items元素有时候混入字符串的怪异现象
    arr[i].comments = items.filter((k) => k instanceof Object)
    setDataSource([...arr])
  }
  return (
    <div
      className={Style.wrapper}
      style={{
        height: `calc(${height} - 20vw - 30px)`,
        position: 'relative',
      }}
    >
      <IscrollLuo
        id="luo"
        noDown
        onUp={handLoadMore}
        detectionHeight
        className={Style.auto}
        options={{
          backgroundColor: 'transparent',
          fontColor: '#333',
          pullupingInfo: '官人稍等',
          click: false,
          preventDefault: false,
        }}
      >
        {dataSource.length > 0
          ? dataSource.map((item, i) => (
            <VcListItem
              data={item}
              key={i}
              index={i}
              onChangeItem={handleChangeItem}
            />
          ))
          : Array(4)
            .fill(null)
            .map((item, i) => {
              return (
                <div style={{ margin: '2vw 0' }} key={i}>
                  <Box display="flex" alignItems="center" margin={1}>
                    <Skeleton variant="circle">
                      <Avatar />
                    </Skeleton>
                    <Box m={1}>
                      <Skeleton variant="rect" width="100%">
                        <Typography className={Style.skeletonText}>
                          .
                        </Typography>
                      </Skeleton>
                    </Box>
                  </Box>
                  <Box margin={1}>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={150}
                    ></Skeleton>
                  </Box>
                </div>
              )
            })}

      </IscrollLuo>{' '}

    </div>
  )
}

export default VcList
