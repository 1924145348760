import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import Style from './index.module.scss'
import Carousel from '@/components/Carousel/index'
import { useRequest } from 'ahooks'
import { historyToday } from '@/utils/fetch'
import { fetchData } from '@/utils/index'
import { useMount } from 'ahooks'
import PubSub from 'pubsub-js'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

function AboutMe() {
  const classes = useStyles()
  const gzh_img = require('@/assets/qrcode.jpg').default
  const xcx_img = require('@/assets/mini_qrcode.png').default
  const [todayData, setTodayData] = React.useState({})
  const { run } = useRequest(fetchData, {
    onSuccess: (e) => {
      setTodayData(e.data)
    },
    onError: (e) => {
      PubSub.publish('error', e.msg)
    },
  })
  useMount(() => {
    run({
      url: historyToday,
      options: {
        method: 'GET',
        params: {},
      },
    })
  })

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>联系我</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <TextField
            label="email"
            InputProps={{
              readOnly: true,
            }}
            style={{ margin: '5px 0' }}
            defaultValue="l0424@outlook.com"
          />
          <TextField
            label="WeChat"
            InputProps={{
              readOnly: true,
            }}
            style={{ margin: '5px 0' }}
            defaultValue="TES_Dovahkiin"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1b-content"
          id="panel1b-header"
        >
          <Typography className={classes.heading}>我的项目</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <Typography variant="subtitle2">微信公众号 & 小程序</Typography>
          <img src={gzh_img} className={Style.img} alt="gzh" />
          <img src={xcx_img} className={Style.img} alt="xcx" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Typography className={classes.heading}>随笔</Typography>
        </AccordionSummary>
        <AccordionDetails className={Style.links}>
          <a
            className={Style.a}
            href="https://www.cnblogs.com/vencen-lum/p/15189891.html"
          >
            React material-ui 弹窗类 组件的bug以及解决思路
          </a>
          <a
            className={Style.a}
            href="https://www.cnblogs.com/vencen-lum/p/15188771.html"
          >
            React+axios 通信问题
          </a>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Typography className={classes.heading}>历史上的今天</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <Carousel data={todayData} speed={2500} />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default AboutMe
