/*
 * @Author: VC
 * @Date: 2021-12-16 14:10:20
 * @LastEditTime: 2022-03-23 11:08:05
 * @FilePath: \index\src\App.jsx
 */
import React, { useState, createContext } from 'react'
import EditMessage from '@/pages/EditMessage'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import VcMain from '@/pages/VcMain'
import Toast from '@/components/VcToast/index'
import PubSub from 'pubsub-js'
import { useToggle, useMount, useUnmount } from 'ahooks'
import VcDrawer from '@/pages/VcDrawer/index'
import Style from './App.module.scss'
import ImageCom from '@/components/ImageCom/index'

export const ImageComContext = createContext({})
const container = {
  transition: 'transform .2s linear',
}
const App = () => {
  const [show, { toggle }] = useToggle(false)
  const [showMenu, { toggle: toggleMenu }] = useToggle(false)
  const [msg, setMsg] = React.useState('emmmm')
  const pluginRef = React.useRef()
  const [image, setImage] = useState('')
  const [showImage, setShow] = useState(false)
  useMount(() => {
    PubSub.subscribe('error', (msg, val) => {
      setMsg(val)
      toggle()
    })
    PubSub.subscribe('handleMenu', (msg, state) => {
      toggleMenu()
    })
    setTimeout(() => {
      document.querySelector('#he-plugin-standard').style.height = 0
      document.querySelector('#he-plugin-standard').style.opacity = 0
    }, 5000)
  })

  useUnmount(() => {
    PubSub.unsubscribe('error')
    PubSub.unsubscribe('handleMenu')
  })

  return (
    <ImageComContext.Provider value={{ setImage, setShow, image }}>
      <div
        className={showMenu ? Style.containerOpen : Style.container}
        style={container}
      >
        {/*天气插件*/}
        <div ref={pluginRef} id="he-plugin-standard"></div>

        <BrowserRouter>
          <Switch>
            <Route exact path="/create-message" component={EditMessage} />
            <Route exact path="/" component={VcMain} />
            <Route path="*" component={VcMain} />
          </Switch>
        </BrowserRouter>
        <aside id="out-side">
          <Toast show={show} onClose={toggle} msg={msg} />
          <VcDrawer menuClose={toggleMenu} show={showMenu} />
        </aside>
        <div style={{ display: showImage ? 'block' : 'none' }}>
          <ImageCom url={image} />
        </div>
      </div>
    </ImageComContext.Provider>
  )
}
export default App
