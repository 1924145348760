/*
 * @Author: VC
 * @Date: 2021-12-16 22:42:04
 * @LastEditTime: 2021-12-19 04:41:49
 * @FilePath: \index\src\components\Carousel\index.jsx
 */
import React from 'react'
import Style from './index.module.scss'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

function Carousel({ data = {}, speed = 2000 }) {
  const [move, setMove] = React.useState({})
  const [list, setList] = React.useState([])
  React.useEffect(() => {
    if (data.result) {
      setList(data.result)
      startCount(data.result.length)
    }
  }, [data.result])

  let count = 0
  const startCount = (l) => {
    setTrans(l)
    setTimeout(startCount.bind(this, l), speed)
  }
  const setTrans = (l) => {
    setMove({
      transform: `translateY(-${20 * count}px)`,
    })
    if (count < l - 2) {
      count += 1
    } else {
      count = 0
    }
  }

  return (
    <div className={Style.wrapper}>
      <ArrowForwardIcon size="sm" />
      <ul className={Style.ul} style={move}>
        {list.map((item, index) => {
          return <li key={index}>{item.title}</li>
        })}
      </ul>
    </div>
  )
}

export default Carousel
