/*
 * @Author: VC
 * @Date: 2021-12-16 22:42:04
 * @LastEditTime: 2021-12-18 18:56:18
 * @FilePath: \index\src\utils\index.js
 */
import { ax } from './fetch'
//在输入框任意位置插入字符
export function insertText(target, str) {
  const selectionStart = target.selectionStart,
    selectionEnd = target.selectionEnd
  let targetValue = target.value
  let newStr1 = targetValue.substring(0, selectionStart),
    newStr2 = targetValue.substring(selectionEnd)
  return `${newStr1}|${str}|${newStr2}`
}

export function getUserInfo() {
  return window.localStorage.getItem('userInfo')
    ? JSON.parse(window.localStorage.getItem('userInfo'))
    : null
}
export function setUserInfo(obj) {
  window.localStorage.setItem('userInfo', JSON.stringify(obj))
}
export function getFond(id) {
  const fond = window.localStorage.getItem('fond')
  if (fond) {
    return JSON.parse(fond).includes(id)
  } else {
    return false
  }
}
export function setFond(id) {
  const fond = window.localStorage.getItem('fond')
  let f = JSON.parse(fond) || []
  if (f.includes(id)) {
    f = f.filter((item) => {
      return item !== id
    })
  } else {
    f.push(id)
  }
  window.localStorage.setItem('fond', JSON.stringify(f))
}
export const fetchData = ({ options, url }) =>
  ax.request({
    ...options,
    url,
  })

export const buildColor = () => {
  const red = [300, 500, 600, 700]
  const rd = Math.floor(Math.random() * red.length)
  return red[rd]
}
export const uploadFile = ({ options, url }) => {
  return ax.request({
    ...options,
    url,
  })
}
