import React from 'react'
import {
  TextField,
  Drawer,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import Style from './index.module.scss'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { insertText, setUserInfo, getUserInfo } from '@/utils/index'
import { makeStyles } from '@material-ui/core/styles'
import { useRequest } from 'ahooks'
import { postComment } from '@/utils/fetch'
import ConfirmDialog from '@/components/ConfirmDialog'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import SendIcon from '@material-ui/icons/Send'
import { fetchData } from '@/utils/index'
import PubSub from 'pubsub-js'

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 12,
  },
}))

function ReComment({
  open,
  toUser,
  toId,
  onClose,
  root_id,
  user,
  reComment = null,
}) {
  const S = useStyles()
  const [time, setTime] = React.useState(0)
  const [visible, setVisible] = React.useState([Style.emoji])
  const [value, setValue] = React.useState('')
  const [element, setElement] = React.useState(null)
  const [dialog, setDialog] = React.useState(false)
  const [readonly, setReadonly] = React.useState(false)
  const [isFocus, setFocus] = React.useState(false)

  React.useEffect(() => {
    if (open) {
      setTime(Date.now())
      setReadonly(false)
      setFocus(false)
    }
  }, [open])
  const { run } = useRequest(fetchData, {
    manual: true,
    onSuccess: (result, params) => {
      if (result) {
        const { data: _data } = params[0].options
        if (!_data.uuid) {
          setUserInfo({
            userName: _data.re_from_user,
            sex: _data.re_sex,
            uuid: result.data?.user_id,
          })
          handleClose(setValue, _data)
        } else {
          regisUser()
        }
      }
    },
    // onError: (err) => {
    //   setErrMessage(`${err.data}->${err.status}->${err.statusText}`)
    //   setMessageOpen(true)
    // },
  })
  const handleEmojiSelect = (e) => {
    if (element) {
      const newStr = insertText(element, e.native)
      setValue(newStr.replace(/\|/g, ''))
      setElement({
        selectionStart: element.selectionStart + 1,
        selectionEnd: element.selectionEnd + 1,
        value: newStr,
      })
    } else {
      setValue(`${value}${e.native}`)
    }
  }

  const showPicker = () => {
    visible.length > 1
      ? setVisible([Style.emoji])
      : setVisible([Style.emoji, Style.emojiShow])
  }
  const handleChange = (val) => {
    setValue(val.target.value)
  }
  const handleSetElement = (val) => {
    const selectionStart = val.target.selectionStart,
      selectionEnd = val.target.selectionEnd,
      text = val.target.value
    setElement({
      selectionStart,
      selectionEnd,
      value: text,
    })
  }

  const handlePostData = (userName, uuid, sex) => {
    let data = {}
    if (reComment) {
      Object.assign(data, reComment, {
        re_from_user: userName,
        re_from_id: uuid,
        re_sex: sex,
        re_content: value,
      })
    } else {
      data = {
        re_content: value,
        re_from_user: userName,
        re_from_id: uuid,
        re_sex: sex,
        re_root_id: root_id,
        re_to_user: toUser,
        re_to_id: toId,
      }
    }
    return data
  }
  const regisUser = () => {
    setDialog(true)
  }
  const handleSummit = () => {
    if (value && value.length <= 100) {
      if (getUserInfo()) {
        const { userName, uuid, sex } = getUserInfo()
        run({
          url: postComment,
          options: {
            method: 'post',
            data: handlePostData(userName, uuid, sex),
          },
        })
      } else {
        regisUser()
      }
    } else {
      PubSub.publish('error', '内容不符合要求哦')
    }
  }

  const dialogCancel = () => {
    setDialog(false)
  }
  const dialogOk = (info) => {
    setDialog(false)
    if (info) {
      setUserInfo(info)
      handleSummit()
    }
  }
  const handleClose = (callback, data) => {
    //在pc端有个bug，在打开时会瞬间触发关闭的回调
    if (Date.now() - time < 1000) return
    //在iOS端有个bug，在激活原生键盘时若突然关闭浮层会导致上游滚动组件无法回复原有高度。因此此方法旨在等待键盘自然收起
    setReadonly(true)
    if (isFocus) {
      setTimeout(() => {
        onClose(data)
      }, 500)
    } else {
      //表示没有激活键盘，可以立即关闭
      onClose(data)
    }
    setVisible([Style.emoji])
    callback instanceof Function && callback('')
  }
  return (
    <Drawer anchor="bottom" open={open} onClose={handleClose}>
      <div className={Style.wrapper}>
        <div className={visible.join(' ')}>
          <Picker onSelect={handleEmojiSelect} style={{ width: '100%' }} />
        </div>
        <TextField
          id="standard-full-width"
          label={'回复 ' + user || ''}
          placeholder="富强、民主、文明、和谐"
          helperText={`还能输入${100 - value.length}个字!`}
          fullWidth
          readOnly={readonly}
          className={S.input}
          minRows={1}
          maxRows={3}
          onFocus={() => setFocus(true)}
          max={100}
          min={1}
          onBlur={handleSetElement}
          multiline
          value={value}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={showPicker}
                  aria-label="toggle visibility"
                  style={{
                    alignSelf: 'flex-start',
                    transform: 'translateY(-23px)',
                  }}
                >
                  {visible.length > 1 ? (
                    <EmojiEmotionsIcon color="secondary" />
                  ) : (
                    <InsertEmoticonIcon color="action" />
                  )}
                </IconButton>
                <IconButton
                  onClick={handleSummit}
                  aria-label="确定评论"
                  style={{
                    alignSelf: 'flex-start',
                    transform: 'translateY(-23px)',
                  }}
                >
                  <SendIcon color="primary" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </div>
      <ConfirmDialog open={dialog} onClose={dialogCancel} onOk={dialogOk} />
    </Drawer>
  )
}

export default ReComment
