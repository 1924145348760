import React from 'react'
import { Snackbar, Button } from '@material-ui/core'

function VcToast({ show, msg, onClose }) {
  const [errMessage, setErrMessage] = React.useState('')

  React.useEffect(() => {
    setErrMessage(msg)
  }, [show, msg])

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      open={show}
      autoHideDuration={2000}
      message={<span> {errMessage} </span>}
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={onClose}>
            关闭
          </Button>
        </React.Fragment>
      }
    ></Snackbar>
  )
}

export default VcToast
