/*
 * @Author: VC
 * @Date: 2021-08-19 00:02:19
 * @LastEditTime: 2021-08-25 01:45:32
 * @FilePath: \index\src\pages\VcChip\index.jsx
 */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Style from './index.module.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'fixed',
    zIndex: 100,
    height: '20vw',
    pointerEvents: 'none',
    top: 0,
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))

function VcChip({ source = [] }) {
  const classes = useStyles()

  const handleClick = () => {
    console.info('You clicked the Chip.')
  }
  const getDelay = () => {
    return Math.ceil(Math.random() * 5000) + 'ms'
  }
  const getVelocity = () => {
    return Math.ceil(Math.random() * 2000 + 3000) + 'ms'
  }
  const randomColor = () => {
    // default" | "primary" | "secondary"
    const s = ['default', 'primary', 'secondary']
    const r = Math.floor(Math.random() * 4)
    return s[r]
  }
  return (
    <div className={classes.root}>
      {source.map((item) => {
        return (
          <Chip
            key={item.created_time}
            size="small"
            avatar={<Avatar> {item.from_user.substring(0, 1)} </Avatar>}
            label={item.from_user}
            onClick={handleClick}
            color={randomColor()}
            className={Style.chipAnim}
            style={{
              animationDelay: getDelay(),
              animationDuration: getVelocity(),
            }}
          />
        )
      })}
    </div>
  )
}

export default VcChip
