import React from 'react'
import lrz from 'lrz'
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  AppBar,
  Input,
  Popover,
  Container,
  Typography,
  Button,
} from '@material-ui/core'
import PubSub from 'pubsub-js'
import Style from './index.module.scss'
import { makeStyles } from '@material-ui/core/styles'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { insertText, setUserInfo, getUserInfo } from '@/utils/index'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { NavLink } from 'react-router-dom'
import { postMessage, uploadUrl, getBaseUrl } from '@/utils/fetch'
import ConfirmDialog from '@/components/ConfirmDialog'
import { fetchData } from '@/utils/index'
import { useRequest, useClickAway } from 'ahooks'
import useMount from './../../hooks/index'
import axios from 'axios'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { ImageComContext } from '@/App'

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 12,
  },
}))
function EditMessage() {
  const S = useStyles()
  const [visible, setVisible] = React.useState([Style.emoji])
  const [value, setValue] = React.useState('')
  const [element, setElement] = React.useState(null)
  const [dialog, setDialog] = React.useState(false)
  const [f, setF] = React.useState(null)
  const [preview, setPreview] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [popOpen, setOpen] = React.useState(false)
  const ref = React.useRef()
  const ref1 = React.useRef()

  React.useEffect(() => {
    if (f) {
      setPreview(f.base64)
    } else {
      setPreview('')
    }
  }, [f])
  useClickAway(() => {
    // setVisible([Style.emoji])
  }, [ref1])
  const handleEmojiSelect = (e) => {
    if (element) {
      const newStr = insertText(element, e.native)
      setValue(newStr.replace(/\|/g, ''))
      setElement({
        selectionStart: element.selectionStart + 1,
        selectionEnd: element.selectionEnd + 1,
        value: newStr,
      })
    } else {
      setValue(`${value}${e.native}`)
    }
  }
  const { run } = useRequest(fetchData, {
    manual: true,
    onSuccess: (result, params) => {
      if (result) {
        const { data: _data } = params[0].options
        if (!_data.uuid)
          setUserInfo({
            userName: _data.from_user,
            sex: _data.sex,
            uuid: result.data.user_id,
          })
        if (f) {
          setOpen(true)
          uploadStart(result.data)
        } else {
          setLoading(false)
          handleToHome()
        }
      }
    },
    // onError: (err) => {
    //   setErrMessage(`${err.data}->${err.status}->${err.statusText}`)
    //   setMessageOpen(true)
    // },
  })
  const showPicker = () => {
    visible.length > 1
      ? setVisible([Style.emoji])
      : setVisible([Style.emoji, Style.emojiShow])
  }
  const handleChange = (val) => {
    setValue(val.target.value)
  }
  const handleSetElement = (val) => {
    const selectionStart = val.target.selectionStart,
      selectionEnd = val.target.selectionEnd,
      text = val.target.value
    setElement({
      selectionStart,
      selectionEnd,
      value: text,
    })
  }

  const handleToHome = () => {
    window.history.go(-1)
  }
  const handlePostData = (userName, uuid, sex) => {
    setLoading(true)
    const data = uuid
      ? { content: value, from_user: userName, from_id: uuid, sex }
      : {
          content: value,
          from_user: userName,
          sex,
        }
    run({
      url: postMessage,
      options: {
        method: 'POST',
        data,
      },
    })
  }
  const regisUser = () => {
    setDialog(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSummit = (event) => {
    setAnchorEl(event.currentTarget)

    if (value && value.length <= 255) {
      if (getUserInfo()) {
        const { userName, uuid, sex } = getUserInfo()
        handlePostData(userName, uuid, sex)
      } else {
        regisUser()
      }
    } else {
      PubSub.publish('error', '内容不符合要求哦')
    }
  }

  const dialogCancel = () => {
    setDialog(false)
  }
  const dialogOk = (info) => {
    setDialog(false)
    if (info) {
      setUserInfo(info)
      handleSummit()
    }
  }
  const uploadStart = ({ root_id }) => {
    const msg = new FormData()
    msg.append('msg', f.file, Math.random() * 10)

    axios
      .put(getBaseUrl(process.env.NODE_ENV) + uploadUrl, msg, {
        headers: {
          'Content-Type': 'multipart/form-data',
          uid: root_id,
        },
      })
      .then(handleToHome)
      .finally(() => {
        setLoading(false)
        setOpen(false)
      })
      .catch(function (err) {
        PubSub.publish('error', '上传失败: ' + err)
      })
  }
  useMount(() => {
    document
      .querySelector('#icon-button-file')
      .addEventListener('change', function () {
        lrz(this.files[0])
          .then(function (rst) {
            // 处理成功会执行
            setF(rst)
          })
          .catch(function (err) {
            PubSub.publish('error', '上传失败: ' + err)
          })
          .always(function () {
            // 不管是成功失败，都会执行
          })
      })
  })
  return (
    <React.Fragment>
      <AppBar position="static" color="inherit" className={Style.appBar}>
        <NavLink to="/" style={{ width: '20%', textAlign: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            style={{ textAlign: 'center', color: '#222' }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </NavLink>
        <Typography variant="h6" className={Style.title}>
          大家踊跃发言
        </Typography>
      </AppBar>
      <Container className={Style.wrapper}>
        <div ref={ref} className={visible.join(' ')}>
          <Picker onSelect={handleEmojiSelect} style={{ width: '100%' }} />
        </div>
        <TextField
          id="standard-full-width"
          label="发表留言"
          placeholder="随便说点啥..."
          helperText={`还能输入${255 - value.length}个字!`}
          fullWidth
          className={S.input}
          minRows={5}
          maxRows={15}
          max={255}
          min={1}
          //  onFocus={hidePicker}
          onBlur={handleSetElement}
          multiline
          value={value}
          onChange={handleChange}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" ref={ref1} onClick={showPicker}>
                <IconButton
                  aria-label="toggle visibility"
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  {visible.length > 1 ? (
                    <EmojiEmotionsIcon color="secondary" />
                  ) : (
                    <InsertEmoticonIcon color="action" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {!!f ? (
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              className={Style.none}
            />
            <Button
              style={{ marginRight: 8 }}
              variant="outlined"
              onClick={() => {
                document.querySelector('#contained-button-file').focus()
                document.querySelector('#contained-button-file').click()
              }}
              color="primary"
            >
              重选
            </Button>
          </label>
        ) : (
          <label htmlFor="icon-button-file">
            <Input
              className={Style.none}
              accept="image/*"
              id="icon-button-file"
              type="file"
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <PhotoCamera />
            </IconButton>
          </label>
        )}
        {!!f ? (
          <Button
            variant="outlined"
            disabled={!!!f}
            onClick={() => setF(null)}
            color="secondary"
          >
            移除
          </Button>
        ) : null}

        <Box
          sx={{
            width: '100%',
            height: 310,
            marginTop: 8,
            backgroundColor: 'primary.dark',
            overflowY: 'scroll',
            '&:hover': {
              backgroundColor: 'primary.main',
              opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          <ImageComContext.Consumer>
            {(value) => (
              <img
                onClick={(event) => {
                  setTimeout(() => {
                    value.setShow(true)
                    value.setImage(preview)
                    event.stopPropagation()
                    event.cancelBubble = true
                  }, 200)
                }}
                alt="preview"
                src={preview}
                className={Style.preview}
                style={{ display: preview ? 'block' : 'none' }}
              />
            )}
          </ImageComContext.Consumer>
        </Box>
        <Button
          variant="contained"
          color="primary"
          aria-describedby="pop"
          size="large"
          disabled={loading}
          onClick={handleSummit}
          style={{ width: '100%', marginTop: 15 }}
        >
          冲鸭
        </Button>
      </Container>

      <ConfirmDialog
        open={dialog}
        onClose={dialogCancel}
        onOk={dialogOk}
        value={999}
      />

      <Popover
        aria-describedby="pop"
        open={popOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography
          style={{ color: '#fff', background: '#333', padding: '8px 12px' }}
          sx={{ p: 2 }}
        >
          留言已发表，正在处理图片...
        </Typography>
      </Popover>
    </React.Fragment>
  )
}

export default EditMessage
