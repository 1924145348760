import React, { useState, useEffect } from 'react'
import Style from './index.module.scss'
import { ImageComContext } from '@/App'

const bgGifs = [
  'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Ffile2.rrxh5.cc%2Fg2%2Fc1%2F2020%2F04%2F03%2F1585897509771.gif&refer=http%3A%2F%2Ffile2.rrxh5.cc&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642643274&t=aba338b6da010889bad04e05ca32c822',
  'https://imglf5.lf127.net/img/OUwzTzhVUmI1clZSMlNPMHVBTmdEWkRzbmdQeU10ZHcwRUVVbG9oU1N1OHVoOXhVbFlCTWR3PT0.gif',
  'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202003%2F05%2F20200305231301_muoxq.thumb.1000_0.gif&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642643253&t=bdb0a31d75276f09dfe9ed30483be159',
  'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201912%2F25%2F20191225104624_avhbs.thumb.700_0.gif&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642905644&t=73b19d4af08f3b001f41e1f1b3646d22',
]
function shuffle(arr) {
  let n = arr.length,
    random
  while (0 !== n) {
    random = (Math.random() * n--) >>> 0 // 无符号右移位运算符向下取整
    ;[arr[n], arr[random]] = [arr[random], arr[n]] // ES6的结构赋值实现变量互换
  }
  return arr
}

function ImageCom({ url }) {
  const Images = bgGifs.map((item) => {
    const img = new Image()
    img.src = item
    img.alt = 'background'
    img.classList.add(Style.gif)

    return img
  })
  const [name, setName] = useState('')
  const imageArr = shuffle(Images)
  useEffect(() => {
    if (url) {
      document.querySelector('#com-wrap').appendChild(imageArr[0])
    } else if (document.querySelector(`.${Style.gif}`)) {
      document
        .querySelector('#com-wrap')
        .removeChild(document.querySelector(`.${Style.gif}`))
    }
  }, [url])
  useEffect(() => {
    if (url) {
      setName(Style['img-anim'])
      imageArr[0].classList.add(Style['bg-anim'])
    } else {
      setName('')
    }
  }, [url])
  return (
    <ImageComContext.Consumer>
      {(values) => (
        <div
          className={Style.wrap}
          id="com-wrap"
          onClick={(event) => {
            setName('')
            imageArr[0].classList.remove(Style['bg-anim'])
            setTimeout(() => {
              values.setImage('')
              values.setShow(false)
            }, 230)
          }}
        >
          <img src={url} alt="demo" key="1" className={name} />
        </div>
      )}
    </ImageComContext.Consumer>
  )
}

export default ImageCom
