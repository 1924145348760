import axios from 'axios'
import qs from 'qs'
import PubSub from 'pubsub-js'

export const getMessage = '/get-message'
export const postMessage = '/create-message'
export const postComment = '/create-comment'
export const historyToday = '/api/historyToday'
export const uploadUrl = '/picture-upload'
export const getBaseUrl = (env) => {
  let base = {
    production: 'https://yoloo.icu/home_server',
    development: 'https://yoloo.icu/home_server',
    test: 'http://localhost:3001',
  }[env]
  if (!base) {
    base = '/'
  }
  return base
}

class NewAxios {
  constructor() {
    this.baseURL = getBaseUrl(process.env.NODE_ENV)
    this.timeout = 5000
    this.withCredentials = false
  }

  // 这里的url可供你针对需要特殊处理的接口路径设置不同拦截器。
  setInterceptors = (instance, url) => {
    instance.interceptors.request.use(
      (config) => {
        // 在这里添加loading
        // 配置token
        //config.headers['Access-Control-Allow-Credentials'] = false

        config.headers['content-type'] =
          config.method.toLowerCase() === 'put'
            ? 'multipart/form-data'
            : 'application/json;charset=UTF-8'

        config.data =
          config.method.toLowerCase() === 'post'
            ? qs.parse(config.data)
            : config.data
        return config
      },
      (err) => {
        PubSub.publish('error', `出现了一个问题 ~~ ${err.message}`)
        Promise.reject(err)
      }
    )

    instance.interceptors.response.use(
      (response) => {
        // 在这里移除loading
        // todo: 想根据业务需要，对响应结果预先处理的，都放在这里
        if (
          response.config.method !== 'options' &&
          response.data.code !== 200
        ) {
          PubSub.publish(
            'error',
            `${response.data.code}->${response.data.message?.code}`
          )
          return null
        }
        if (response.data)
          return {
            status: response.status,
            msg: response.statusText,
            data: response.data.data,
          }
      },
      (err) => {
        if (err.response) {
          // 响应错误码处理
          switch (err.response.status) {
            case '403':
              break
            // todo: handler other status code
            default:
              break
          }
          PubSub.publish(
            'error',
            `${err.response.data.code}->${err.response.data.message?.code}`
          )
          return Promise.reject(err.response)
        }
        if (!window.navigator.online) {
          // 断网处理
          // todo: jump to offline page
          PubSub.publish('error', '网络不佳...')
          return -1
        }
        return Promise.reject(err)
      }
    )
  }

  request(options) {
    // 每次请求都会创建新的axios实例。
    const instance = axios.create()
    const config = {
      // 将用户传过来的参数与公共配置合并。
      ...options,
      baseURL: this.baseURL,
      timeout: this.timeout,
      withCredentials: this.withCredentials,
    }

    // 配置拦截器，支持根据不同url配置不同的拦截器。
    this.setInterceptors(instance, options.url)
    return instance(config) // 返回axios实例的执行结果
  }
}

export const ax = new NewAxios()
