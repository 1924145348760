import React from 'react'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Style from './index.module.scss'
import TextField from '@material-ui/core/TextField'
import PubSub from 'pubsub-js'

const options = ['男生', '女生', '其他']
function ConfirmDialog(props) {
  const { onClose, open, onOk, ...other } = props
  const [value, setValue] = React.useState('')
  const [name, setName] = React.useState('')
  const radioGroupRef = React.useRef(null)

  // React.useEffect(() => {
  //   if (!open) {
  //     setValue(valueProp)
  //   }
  // }, [valueProp, open])

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    if (name && name.length <= 8 && value) {
      const alias = new Map([
        ['男生', 1],
        ['女生', 2],
        ['其他', 3],
      ])

      onOk({ sex: alias.get(value), userName: name })
    } else if (!name || name.length > 8) {
      PubSub.publish('error', '昵称规则不合规哦！')
    } else {
      PubSub.publish('error', '哒咩！检查是否有漏填')
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  const handleChangeName = ({ target }) => {
    setName(target.value)
  }

  return (
    <Dialog
      maxWidth="xl"
      minWidth="90vw"
      TransitionProps={{}}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <span className={Style.title}>第一次互动时请给自己添加一个马甲</span>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          <ListItem>
            <TextField
              id="outlined-basic"
              label="你喜欢的昵称"
              placeholder="8个字符内"
              variant="outlined"
              value={name}
              onChange={handleChangeName}
              maxLength={8}
              minLength={1}
            />
          </ListItem>
          <ListItem>
            <RadioGroup
              ref={radioGroupRef}
              aria-label="ringtone"
              name="ringtone"
              value={value}
              onChange={handleChange}
            >
              {options.map((option) => (
                <FormControlLabel
                  value={option}
                  key={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="secondary">
          放弃了
        </Button>
        <Button onClick={handleOk} color="primary">
          就这样
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
