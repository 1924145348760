import React from 'react'
import { Fab } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import Style from './index.module.scss'
import { NavLink } from 'react-router-dom'

function AddMessage() {
  return (
    <NavLink to="/create-message">
      <Fab color="secondary" aria-label="edit" className={Style.wrapper}>
        <EditIcon />
      </Fab>
    </NavLink>
  )
}

export default AddMessage
